.bodu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align for better control */
  width: 105vw;
}

.line {
  height: 1px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.line svg {
  width: 100%;
  height: 420px;
  position: absolute;
  top: -250px;
}

.line svg path {
  stroke: black;
  stroke-width: 1.4px; /* Adjust width here if needed */
  fill: none;
}

.line .box {
  height: 40px;
  width: 100%;
  position: relative;
  top: -20px;
  z-index: 1;
}

.line .box:hover {
  height: 500px;
  top: -250px;
}
