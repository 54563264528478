.galleryy{
    height: 175vh;
    background: rgb(0, 0, 0);
    position: relative;
    display: flex;
    gap: 2vw;
    padding: 2vw;
    box-sizing: border-box;
    overflow: hidden;
    .column{
        position: relative;
        height: 100%;
        width: 25%;
        min-width: 250px;
        display: flex;
        flex-direction: column;
        gap: 2vw;
        .imageContainer{
            height: 100%;
            width: 100%;
            position: relative;
            border-radius: 1vw;
            overflow: hidden;
            img{
                object-fit: cover;
            }
        }
        &:nth-of-type(1){
            top: -45%;
        }
        &:nth-of-type(2){
            top: -95%;
        }
        &:nth-of-type(3){
            top: -45%;
        }
        &:nth-of-type(4){
            top: -75%;
        }
    }
}
.spacer{
    height: 100vh;
}