*,
*::after,
*::before {
  box-sizing: border-box;
}

.bodii {
  font-size: 18px;
  --color-text: #fff;
  --color-bg: #131417;
  --color-link: #aaa;
  --color-link-hover: #fff;
  --color-label: #adadad;
  --color-text-alt: #575757;
  --color-caption: #fff;
  margin: 0;
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: "tenon", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.frame {
  position: relative;
  width: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: "title" "prev" "sponsor" "demos";
  grid-gap: 0.5rem;
  justify-items: start;
  align-self: start;
  justify-self: start;
  pointer-events: none;
  align-items: center;
  font-size: 0.85rem;
  opacity: 0.7;
}

body #cdawrap {
  justify-self: start;
}

.frame a {
  pointer-events: auto;
}

.frame a:not(.frame__title-back) {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.frame a:not(.frame__title-back)::before {
  content: "";
  height: 1px;
  width: 100%;
  background: currentColor;
  position: absolute;
  top: 90%;
  transition: transform 0.3s;
  transform-origin: 0% 50%;
}

.frame a:not(.frame__title-back):hover::before {
  transform: scaleX(0);
  transform-origin: 100% 50%;
}

.frame__title {
  grid-area: title;
  display: flex;
}

.frame__title-main {
  font-size: inherit;
  margin: 0;
  font-weight: inherit;
}

.frame__title-back {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.frame__title-back span {
  display: none;
}

.frame__title-back svg {
  fill: currentColor;
}

.frame__prev {
  grid-area: prev;
}

.project {
  padding: 1rem;
  max-width: 1000px;
  margin: 20vh auto;
  margin-top: 0;
  display: grid;
  grid-column-gap: 7vw;
  grid-row-gap: 0.5rem;
}

.project--intro {
  grid-template-columns: 1fr;
  grid-template-areas:
    "label-name"
    "name"
    "label-date"
    "date"
    "title"
    "label-mission"
    "mission";
}

.project--details {
  grid-template-areas: "label-default" "paragraph";
  grid-template-columns: 1fr;
}

.project--left {
  justify-content: start;
}

.project--right {
  justify-content: end;
}

.project--details p {
  grid-area: paragraph;
  max-width: 400px;
}

.project__label--default {
  grid-area: label-default;
}

.project p {
  line-height: 1.4;
  margin: 0;
  color: var(--color-text-alt);
}

.project__label {
  color: var(--color-label);
}

.project__label--name {
  grid-area: label-name;
}

.project__label--date {
  grid-area: label-date;
}

.project__label--mission {
  grid-area: label-mission;
}

.project__name {
  grid-area: name;
}

.project__date {
  grid-area: date;
}

.project__mission {
  grid-area: mission;
  line-height: 1.4;
}

.project__mission p:first-child {
  grid-area: p1;
}

.project__mission p:child {
  grid-area: p2;
}

.project__mission p {
  color: var(--color-text-alt);
}

.project__title {
  grid-area: title;
  font-size: clamp(2rem, 13vw, 8rem);
  font-weight: 400;
  margin: 10vh 0;
  line-height: 1;
}

@media (max-width: 480px) {
  .project__title {
    font-size: clamp(2rem, 9vw, 8rem);
  }}


.project__heading {
  color: var(--color-label);
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

.gallery-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40vh auto;
  overflow: hidden;
}

.gallery-wrap--auto {
  height: auto;
}

.gallery-wrap--large {
  height: 110vh;
}

.gallery-wrap--dense {
  margin: 0;
}

.gallery {
  position: relative;
  width: 100%;
  height: 100%;
  flex: none;
}

.gallery--breakout {
  width: min-content;
}

.gallery__item {
  background-position: 50% 50%;
  background-size: cover;
  flex: none;
  border-radius: 6px;
  position: relative;
  filter: brightness(1);
}

.gallery__item-cut {
  overflow: hidden;
  display: grid;
  place-items: center;
}

.gallery__item-inner {
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.caption {
  font-size: clamp(1rem, 3vw, 1.75rem);
  z-index: 101;
  color: var(--color-caption);
  font-weight: 400;
}

.gallery--row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  --size-factor: 1.25;
  --item-height: 20vh;
}

@media (max-width: 480px) {
  .gallery--row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    --size-factor: 1.25;
    --item-height: 7vh;
  }
  
}

.gallery--row .gallery__item {
  width: auto;
  aspect-ratio: 2 / 3;
}

.gallery--row .gallery__item--s {
  height: var(--item-height);
}

.gallery--row .gallery__item--m {
  height: calc(var(--size-factor) * var(--item-height));
}

.gallery--row .gallery__item--l {
  height: calc(var(--size-factor) * 2 * var(--item-height));
}

.gallery--row .gallery__item--xl {
  z-index: 100;
  height: calc(var(--size-factor) * 3 * var(--item-height));
}

.gallery--switch.gallery--row .gallery__item--m,
.gallery--switch.gallery--row .gallery__item--l {
  height: var(--item-height);
}

.gallery--row .caption {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: -50vh;
  left: 0;
  padding: 4.5vw;
  max-width: 730px;
  opacity: 0;
}

.gallery--switch.gallery--row .caption {
  bottom: 0;
  opacity: 1;
}

.gallery--one {
  display: grid;
  place-items: center;
}

.gallery--one .gallery__item {
  width: 400%;
  height: 400%;
  filter: brightness(1) hue-rotate(90deg);
}

.gallery--switch.gallery--one .gallery__item {
  width: 100%;
  height: 100%;
  filter: brightness(0.7) hue-rotate(0deg);
}

.gallery--one .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  margin: 100vh 0 0 -50vw;
  font-size: clamp(2rem, 5vw, 3rem);
  display: grid;
  place-items: center;
}

.gallery--switch.gallery--one .caption {
  margin-top: -50vh;
}

.gallery--grid {
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  row-gap: 2.5vw;
  column-gap: 3vw;
}

.gallery--grid .gallery__item {
  height: 33vh;
  width: 33vw;
}

.gallery--switch.gallery--grid {
  gap: 0;
}

.gallery--switch.gallery--grid .gallery__item {
  height: 110vh;
  width: 110vw;
  filter: brightness(0.65);
}

.gallery--grid .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding: 0;
  top: 50%;
  left: 50%;
  margin-top: 50vh;
  margin-left: -50vw;
  display: grid;
  place-items: center;
  max-width: none;
  opacity: 0;
}
.gallery--switch.gallery--grid .caption {
  margin-top: -40vh;
  opacity: 1;
}

.gallery--grid .caption p {
  padding: 50vh 30vw 10vh 10vw;
}

.gallery--switch.gallery--grid .caption p {
  margin-top: 0;
}

.gallery--stack {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: start;
  gap: 2rem;
  padding: 2rem;
  --offset: 1rem;
}

.gallery--stack .gallery__item {
  border-radius: 1.5vw;
  width: 25vw;
  height: 35vw;
  z-index: 1;
}

.gallery--stack-glass .gallery__item {
  filter: opacity(1);
}

.gallery--switch.gallery--stack .gallery__item {
  grid-area: 1 / 1 / 2 / 2;
}

.gallery--stack .caption {
  position: absolute;
  bottom: 0;
  left: 100vw;
  width: 100vw;
  padding: 5vw;
  color: var(--color-text-alt);
  opacity: 0;
  z-index: 0;
}

.gallery--stack .caption p {
  margin: 0;
}

.gallery--switch.gallery--stack .caption {
  left: 0;
  opacity: 1;
}

.gallery--stack-inverse .gallery__item:nth-child(5) {
  z-index: 2;
}
.gallery--stack-inverse .gallery__item:nth-child(4) {
  z-index: 3;
}
.gallery--stack-inverse .gallery__item:nth-child(3) {
  z-index: 4;
}
.gallery--stack-inverse .gallery__item:nth-child(2) {
  z-index: 5;
}
.gallery--stack-inverse .gallery__item:nth-child(1) {
  z-index: 6;
}

.gallery--switch.gallery--stack .gallery__item:nth-child(2) {
  margin-left: var(--offset);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(3) {
  margin-left: calc(var(--offset) * 2);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(4) {
  margin-left: calc(var(--offset) * 3);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(5) {
  margin-left: calc(var(--offset) * 4);
}

.gallery--switch.gallery--stack .gallery__item:nth-child(6) {
  margin-left: calc(var(--offset) * 5);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(2) {
  filter: brightness(0.8);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(3) {
  filter: brightness(0.7);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(4) {
  filter: brightness(0.6);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(5) {
  filter: brightness(0.5);
}

.gallery--switch.gallery--stack-dark .gallery__item:nth-child(6) {
  filter: brightness(0.4);
}

.gallery--switch.gallery--stack-glass .gallery__item {
  opacity: 0.7;
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(2) {
  transform: scale(0.98);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(3) {
  transform: scale(0.96);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(4) {
  transform: scale(0.94);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(5) {
  transform: scale(0.92);
}

.gallery--switch.gallery--stack-scale .gallery__item:nth-child(6) {
  transform: scale(0.9);
}

.gallery--switch.gallery--gridtiny .caption {
  opacity: 1;
}

.gallery--switch .gallery__item--center {
  height: 100vh;
  width: 100vw;
  aspect-ratio: auto;
  filter: brightness(0.5);
}

.gallery--switch.gallery--bento .caption {
  margin-top: -50vh;
}

.gallery--grid10 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
}

.gallery--switch.gallery--grid10 {
  grid-template-columns: 1fr 300px 1fr;
  grid-template-rows: 1fr 60vh 1fr;
  grid-gap: 0;
}

.gallery--switch.gallery--grid10 .gallery__item {
  grid-area: 2 / 2 / 3 / 3;
}

.gallery--grid10 .caption {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  margin: 100vh 0 0 -50vw;
  display: grid;
  place-items: center;
  font-size: clamp(2rem, 8vw, 6rem);
}

.gallery--switch.gallery--grid10 .caption {
  opacity: 1;
  margin-top: -50vh;
}

@media screen and (min-width: 53em) {
  .frame {
    grid-template-columns: auto auto auto 1fr;
    grid-template-areas: "title prev ... sponsor";
    align-content: space-between;
    justify-items: start;
    grid-gap: 2rem;
  }
  .frame__demos {
    justify-self: end;
  }
  .project--intro {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "label-name name"
      "label-date date"
      "title title"
      "label-mission mission";
  }

  .project--details {
    grid-template-areas: "label-default paragraph";
    grid-template-columns: auto auto;
  }
  .project__label {
    text-align: right;
  }
  .project__mission {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "p1 ..." "... p2";
    column-gap: 2rem;
  }
  .gallery--grid10 {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .gallery--grid10:not(.gallery--switch) .pos-1 {
    grid-area: 1 / 1;
  }
  .gallery--grid10:not(.gallery--switch) .pos-2 {
    grid-area: 1 / 2;
  }
  .gallery--grid10:not(.gallery--switch) .pos-3 {
    grid-area: 1 / 4;
  }
  .gallery--grid10:not(.gallery--switch) .pos-4 {
    grid-area: 1 / 5;
  }
  .gallery--grid10:not(.gallery--switch) .pos-5 {
    grid-area: 3 / 6;
  }
  .gallery--grid10:not(.gallery--switch) .pos-6 {
    grid-area: 1 / 7;
  }
  .gallery--grid10:not(.gallery--switch) .pos-7 {
    grid-area: 1 / 9;
  }
  .gallery--grid10:not(.gallery--switch) .pos-8 {
    grid-area: 3 / 10;
  }
  .gallery--grid10:not(.gallery--switch) .pos-9 {
    grid-area: 2 / 8;
  }
  .gallery--grid10:not(.gallery--switch) .pos-10 {
    grid-area: 4 / 9;
  }
  .gallery--grid10:not(.gallery--switch) .pos-11 {
    grid-area: 3 / 8;
  }
  .gallery--grid10:not(.gallery--switch) .pos-12 {
    grid-area: 2 / 2;
  }
  .gallery--grid10:not(.gallery--switch) .pos-13 {
    grid-area: 3 / 1;
  }
  .gallery--grid10:not(.gallery--switch) .pos-14 {
    grid-area: 3 / 4;
  }
  .gallery--grid10:not(.gallery--switch) .pos-15 {
    grid-area: 4 / 3;
  }
  .gallery--grid10:not(.gallery--switch) .pos-16 {
    grid-area: 4 / 7;
  }

  .gallery--stack .caption {
    bottom: 40%;
    width: 60vw;
    max-width: 800px;
  }

  .gallery--switch.gallery--stack .caption {
    left: 40vw;
  }

  body #cdawrap {
    justify-self: end;
  }
}
