$mf-color-base: #000 !default;
$mf-color-text: #fff !default;
$root: &; // Define $root variable

$featuredProjects: (
    sleepest: #aa37bb,
    riyadh: #495db5,
    ferrumpipe: #4ebb94,
    ulesson: #ff6b46
);

// Cursor styles
.mf-cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 250;
    direction: ltr;
    contain: layout style size;
    pointer-events: none;
    transition: opacity 0.3s, color 0.4s;

    &:before {
        content: "";
        position: absolute;
        top: -24px;
        left: -24px;
        display: block;
        width: 48px;
        height: 48px;
        transform: scale(0.2);
        background: currentColor;
        border-radius: 50%;
        transition: transform 0.25s ease-in-out, opacity 0.1s;
    }

    @each $name, $value in $featuredProjects {
        &.#{$name} {
            &:before {
                background: $value;
            }
        }
    }

    // Media query to hide on mobile screens
    @media (max-width: 768px) {
        display: none; // Hide the cursor on mobile screens
    }

    &.-media {
        z-index: -1;

        &.-media-lg {
            .mf-cursor-media {
                transform: scale(1.5);
            }
        }

        .mf-cursor-media {
            position: absolute;
            width: 350px;
            height: 350px;
            margin: -200px 0 0 -200px;
            transition: transform .3s;

            img, video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;

                @supports (object-fit: cover) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: translateZ(0);
                }
            }

            &-box {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                transform: scale(0) translateZ(0);
                padding: 1px;
                opacity: 0;
                border-radius: 50%;
                transition: transform 0.35s, opacity 0.2s 0.2s;

                #{$root}.-media & {
                    opacity: 1;
                    transform: scale(0.696);
                    transition-duration: 0.4s, 0.4s;
                    transition-delay: 0s, 0s;
                }
            }
        }

        // Sizes
        &.-mf-cursor-md {
            &:before {
                transform: scale(1.3);
            }
        }

        &.-mf-cursor-lg {
            &:before {
                transform: scale(1.9);
            }
        }
    }

    &.-inverse {
        color: invert($mf-color-base);
    }

    &.-exclusion {
        @supports (mix-blend-mode: exclusion) {
            mix-blend-mode: exclusion;

            &:before {
                background: invert($mf-color-base);
            }
        }
    }

    &.-pointer {
        &:before {
            transform: scale(0.15);
        }
    }

    &.-text {
        &:before {
            opacity: 0.85;
            transform: scale(1.7);
        }

        &.-active:before {
            transform: scale(1.6);
            transition-duration: 0.2s;
        }
    }

    &.-icon {
        &:before {
            transform: scale(1.5);
        }

        &.-active:before {
            transform: scale(1.4);
        }
    }

    &.-hidden {
        &:before {
            transform: scale(0);
        }
    }

    &-text {
        position: absolute;
        top: -18px;
        left: -18px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(0) rotate(10deg);
        opacity: 0;
        color: $mf-color-text;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        transition: opacity 0.4s, transform 0.3s;

        #{$root}.-text &,
        #{$root}.-icon & {
            opacity: 1;
            transform: scale(1);
        }
    }
}

/** Other states **/
@supports (mix-blend-mode: exclusion) {
    .mf-cursor.-media.-media-blend {
        z-index: 150;
    }

    .mf-cursor.-exclusion,
    .mf-cursor.-media.-media-blend,
    .mf-cursor.-opaque {
        mix-blend-mode: exclusion;
    }
}
