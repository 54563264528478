// // base
// .flip {
//     position: relative;
  
//     > .front,
//     > .back {
//       display: block;
//       transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
//       transition-duration: .5s;
//       transition-property: transform, opacity;
//     }
  
//     > .front {
//       transform: rotateY(0deg);
//     }
  
//     > .back {
//       position: absolute;
//       opacity: 0;
//       top: 0;
//       left: 0;
//       width: 100%;
//       height: 100%;
//       transform: rotateY(-180deg);
//     }
  
//     &:hover {
//       > .front {
//         transform: rotateY(180deg);
//       }
  
//       > .back {
//         opacity: 1;
//         transform: rotateY(0deg);
//       }
//     }
//   }
  
//   // custom
//   .flip {
//     position: relative;
//     display: inline-block;
//     margin: 0 1rem 2rem;
//     width: 600px;
//     > .front,
//     > .back {
//       border: 1px solid #000;
//       display: block;
//       width: 100%;
//       height: 400px;
//       background-size: cover !important;
//       background-position: center !important;
//       background: #fff;
  
//       p {
//         font-size: 0.9125rem;
//         line-height: 160%;
//         color: #999;
//       }
//     }
//   }
  
//   // Responsive adjustments
//   @media (max-width: 1024px) {
//     .flip {
//       width: 100%; // Full width on smaller screens
//       margin: 1rem 0; // Adjust margin for smaller screens
  
//       > .front,
//       > .back {
//         height: auto; // Let height adjust automatically
//       }
//     }
  
//     .back .flex-col {
//       flex-direction: column;
//     }
  
//     .text-shadow {
//       text-shadow: 1px 1px rgba(0, 0, 0, 0.04), 2px 2px rgba(0, 0, 0, 0.04),
//         3px 3px rgba(0, 0, 0, 0.04), 4px 4px rgba(0, 0, 0, 0.04),
//         0.125rem 0.125rem rgba(0, 0, 0, 0.04), 6px 6px rgba(0, 0, 0, 0.04),
//         7px 7px rgba(0, 0, 0, 0.04), 8px 8px rgba(0, 0, 0, 0.04),
//         9px 9px rgba(0, 0, 0, 0.04), 0.3125rem 0.3125rem rgba(0, 0, 0, 0.04),
//         11px 11px rgba(0, 0, 0, 0.04), 12px 12px rgba(0, 0, 0, 0.04),
//         13px 13px rgba(0, 0, 0, 0.04), 14px 14px rgba(0, 0, 0, 0.04),
//         0.625rem 0.625rem rgba(0, 0, 0, 0.04), 16px 16px rgba(0, 0, 0, 0.04),
//         17px 17px rgba(0, 0, 0, 0.04), 18px 18px rgba(0, 0, 0, 0.04),
//         19px 19px rgba(0, 0, 0, 0.04), 1.25rem 1.25rem rgba(0, 0, 0, 0.04);
//     }
//   }
  
// base
.flip {
  position: relative;
  display: inline-block;
  margin: 0 1rem 2rem;
  width: 600px; // Adjust for desktop screens

  > .front,
  > .back {
    display: block;
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
    width: 100%;
    height: 400px;
    background-size: cover !important;
    background-position: center !important;
    background: #fff;

    p {
      font-size: 0.9125rem;
      line-height: 160%;
      color: #999;
    }
  }

  > .front {
    transform: rotateY(0deg);
  }

  > .back {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
  }

  &:hover {
    > .front {
      transform: rotateY(180deg);
    }

    > .back {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
}

// Add the border only for medium and large screens
@media (min-width: 768px) {
  .flip > .front,
  .flip > .back {
    border: 1px solid #000; // Apply border for md and lg screens
  }
}

// Responsive adjustments for smaller screens
@media (max-width: 767px) {
  .flip {
    width: 100%; // Full width on smaller screens
    margin: 3rem 0; // Adjust margin for smaller screens

    > .front,
    > .back {
      height: auto; // Let height adjust automatically for content
      border: none; // Remove border for mobile screens
    }

    &:hover {
      > .front,
      > .back {
        transform: none; // Disable flip on touch devices
        opacity: 1; // Both sides visible on smaller screens
      }
    }
  }

  .back .flex-col {
    flex-direction: column; // Column layout for mobile view
  }

  // Text shadow for a styled effect on smaller screens
  .text-shadow {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.04), 
                 2px 2px rgba(0, 0, 0, 0.04),
                 3px 3px rgba(0, 0, 0, 0.04), 
                 4px 4px rgba(0, 0, 0, 0.04), 
                 0.125rem 0.125rem rgba(0, 0, 0, 0.04), 
                 6px 6px rgba(0, 0, 0, 0.04),
                 7px 7px rgba(0, 0, 0, 0.04), 
                 8px 8px rgba(0, 0, 0, 0.04), 
                 9px 9px rgba(0, 0, 0, 0.04),
                 0.3125rem 0.3125rem rgba(0, 0, 0, 0.04), 
                 11px 11px rgba(0, 0, 0, 0.04),
                 12px 12px rgba(0, 0, 0, 0.04),
                 13px 13px rgba(0, 0, 0, 0.04), 
                 14px 14px rgba(0, 0, 0, 0.04), 
                 0.625rem 0.625rem rgba(0, 0, 0, 0.04),
                 16px 16px rgba(0, 0, 0, 0.04), 
                 17px 17px rgba(0, 0, 0, 0.04),
                 18px 18px rgba(0, 0, 0, 0.04),
                 19px 19px rgba(0, 0, 0, 0.04), 
                 1.25rem 1.25rem rgba(0, 0, 0, 0.04);
  }
}
