.page-loader-wrapper {
    position: relative;
  }
  
  .loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: 1000;
  }
  
  .loader svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .page-content {
    position: relative;
    z-index: 1;
  }
  